import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Button from '../../../components/Button';
import Layout from "../../../components/Layout";
import SEO from "../../../components/seo";
import Title from '../../../components/Title';
import Typography from '../../../components/Typography';
import CallToAction from '../../CallToAction';
import Field from './Field';
import ResultField from './ResultField';
import styles from './styles.module.sass';

const $FLEET_PRICE = 100;

export default ({ seo, location }) => {
  const [formData, setFormData] = React.useState({});
  const [result, setResult] = React.useState({});
  const setFormKey = key => data => setFormData({
    ...formData,
    [key]: data
  });
  const disabled = !(formData.vehicles && formData.routes && formData.timeCons && formData.timeAdmin && formData.wage);
  const hasResult = Object.keys(result).length;

  return (
    <Layout overlay
      url='_url:fleet-roi'>
      <SEO {...seo} location={location}/>
      <div className={styles.container}>
        <Title variant='h1' weight='bold'
          className={styles.title}>
          {i18n('It pays to use')}<br/>
          {i18n('our services')}
        </Title>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            className={styles.columnContainer}>
            <Grid item container
              direction='column'
              className={styles.cardContainer}>
              <Grid item container
                direction='column'
                className={styles.fieldsContainer}>
                <Typography variant='h5'
                  align='left'
                  weight='bold'
                  className={styles.cardTitle}>
                  {i18n('Enter your consumption')}
                </Typography>
                <Field setFormData={setFormKey('vehicles')}
                  value={formData.vehicles}
                  text={i18n('Number of vehicles')}/>
                <Field setFormData={setFormKey('routes')}
                  value={formData.routes}
                  text={i18n('Number of routes per day per employee/vehicle')}/>
                <Field setFormData={setFormKey('timeCons')}
                  value={formData.timeCons}
                  text={i18n('Time consumption for driving registration per route')}
                  placeholder='min'/>
                <Field setFormData={setFormKey('timeAdmin')}
                  value={formData.timeAdmin}
                  text={i18n('Administrative work on one\'s fleet per day')}
                  placeholder='min'/>
                <Field setFormData={setFormKey('wage')}
                  value={formData.wage}
                  text={i18n('Employee hourly wage')}
                  placeholder='DKK'/>
              </Grid>
              <Grid item container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={styles.savingsContainer}>
                <Button className={clsx(
                    styles.button,
                    disabled ? styles.buttonDisabled : ''
                  )}
                  onClick={disabled
                  ? () => {}
                  : () => {
                    const timePerDay = formData.vehicles * formData.routes * formData.timeCons + formData.timeAdmin;
                    const timePerMonth = timePerDay * 20;
                    const spendingPerMonth = formData.wage * (timePerMonth / 60);
                    const spendingPerYear = 12 * spendingPerMonth;
                    const savings = spendingPerYear - (12 * $FLEET_PRICE * formData.vehicles);
                    setResult({
                      timePerDay,
                      timePerMonth,
                      spendingPerMonth,
                      spendingPerYear,
                      savings,
                    });
                  }}>
                  {i18n('Calculate')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            className={clsx(
              styles.columnContainer,
              styles.columnHidden,
              hasResult && styles.columnShown
            )}>
            <Grid item direction='column' container className={styles.cardContainer}
              justifyContent='space-between'>
              <Typography variant='h5'
                align='left'
                weight='bold'
                className={styles.resultCardTitle}>
                {i18n('Consumption overview')}
              </Typography>
              <Grid item container
                direction='column'
                justifyContent='center'
                className={styles.resultFieldsContainer}>
                <ResultField value={result.timePerDay}
                  unit='min'
                  text={i18n('Time consumption per day')}/>
                <ResultField value={result.timePerMonth}
                  unit='min'
                  text={i18n('Time consumption per month (20 days)')}/>
                <ResultField value={result.spendingPerMonth}
                  text={i18n('Spending per month')}
                  unit='DKK'/>
                <ResultField value={result.spendingPerYear}
                  text={i18n('Expenditure per year')}
                  unit='DKK'/>
              </Grid>
              <Grid item container
                direction='column'
                justifyContent='center'
                className={styles.savingsContainer}>
                <ResultField savings
                  value={result.savings}
                  text={i18n('Savings per year using our fleet service')}
                  unit='DKK'/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <CallToAction/>
    </Layout>
  )
};