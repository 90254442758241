import React from 'react';
import Content from '../views/ROI/Fleet';

export default ({location}) => <Content location={location} seo={{
  title: 'Estimate your savings on your fleet with ROI calculator',
  lang: 'en',
  description: 'Reduce costs with driving logbook. Use our simple ROI calculator to see how much you will save with OBI+ fleet services.',
  meta: [{
    name: 'keywords',
    content: 'roi calculator for fleet'
  }]
}}/>